.projects-section {
	padding-top: 7rem;
	padding-bottom: 7rem;
	background-color: #f1f1f1;

	h2 {
		color: $green-accent;
		font-size: 1.4rem;
		font-weight: 700;
		letter-spacing: 0.15rem;
	}

	h3 {
		font-size: 3.2rem;
		font-weight: 600;
	}

	h4 {
		font-weight: 700;
		font-size: 1.5rem;
	}

	p {
		color: $text-color;
		line-height: 1.5;
	}

	&__header {
		text-align: center;
		margin-bottom: 3rem;
	}

	hr {
		width: 240px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	.projects-grid {
		margin-top: 4rem;
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 3rem;

		.card {
			background: #fff;
			display: flex;
			flex-direction: column;

			&__body {
				padding: 2rem;
				height: 100%;
				display: flex;
				flex-direction: column;
			}

			&__actions {
				margin-top: auto;

				a.btn {
					display: block;

					&:first-of-type {
						margin-bottom: 6px;
					}
				}
			}
		}
	}

	@media (min-width: 800px) {
		.projects-grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 3rem;
		}
	}
}
