@import '../node_modules/normalize.css/normalize.css';
@import 'variables';
@import 'global';
@import 'header';
@import 'hero';
@import 'buttons';
@import 'about';
@import 'projects';
@import 'contact';
@import 'footer';
