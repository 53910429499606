.hero {
	background: url('../images/starry-sky-rotating-cropped.jpg');
	background-size: cover;
	background-position-y: 100%;
	background-repeat: no-repeat;
	padding: 100px 0;
	min-height: 100vh;
	color: #fff;

	.dark-overlay {
		height: 100vh;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.7);
	}

	.hero-cta {
		height: 100%;
		display: flex;
		flex-direction: column;
		width: 80%;
		margin: auto;
		justify-content: center;

		@media (min-width: 1000px) {
			align-items: center;
		}

		&__headline {
			margin-bottom: 2rem;

			p::before {
				content: '';
				width: 40px;
				height: 2px;
				background-color: #3bb99b;
				margin-top: -1.5px;
				position: absolute;
				left: -5px;
				top: 50%;
			}
			p {
				font-size: 0.8rem;
				font-weight: 900;
				letter-spacing: 0.3rem;
				position: relative;
				padding-left: 48px;
			}

			h1 {
				font-size: 2.6rem;
			}

			h2 {
				font-size: 2rem;
			}
		}

		@media (min-width: 800px) {
			.hero-cta {
				&__buttons {
					margin-bottom: 9rem;
				}
			}

			&__headline {
				p {
					font-size: 1rem;
				}

				h1 {
					font-size: 4.5rem;
				}
				h2 {
					font-size: 3rem;
				}
			}
		}

		&__buttons {
			margin-bottom: 4rem;
		}

		&__scroll {
			align-self: end;

			.scroll-link {
				font-size: 0.6rem;
				letter-spacing: 0.3rem;
				font-weight: 600;
				padding-right: 30px;
			}
		}

		&__scroll::after {
			border-bottom: 2px solid $green-accent;
			border-right: 2px solid $green-accent;
			content: '';
			display: block;
			height: 9px;
			width: 9px;
			margin-top: -15px;
			pointer-events: none;
			position: relative;
			left: 139px;
			top: -24%;
			transform-origin: 66% 66%;
			transform: rotate(45deg);
		}
	}
}
