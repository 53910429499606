/* BASE BUTTON STYLES ================*/
.btn {
	text-decoration: none;
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.94rem 0.75rem;
	font-size: 0.8rem;
	line-height: 1.5;
	transition: color 0.5s ease-in-out, background-color 0.5s ease-in-out,
		border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
	letter-spacing: 0.2rem;
}

.btn:hover {
	text-decoration: none;
}

.btn:focus {
	outline: 0;
}

/* WHITE */
.btn-outline-white {
	color: #fff;
	border: 2px solid #fff;
	width: 210px;
	margin: 1.5rem 1rem 0 0;
}

.btn-outline-white:hover {
	color: #000;
	background-color: #fff;
}

/* ACCENT =====================*/
.btn-accent {
	color: #fff;
	background-color: $green-accent;
	border-color: $green-accent;
}

.btn-accent:hover {
	color: #fff;
	background-color: $green-accent-hover-bg;
	border-color: $green-accent-hover-border;
}

.btn-accent:focus {
	color: #fff;
	background-color: $green-accent-hover-bg;
	border-color: $green-accent-hover-border;
	box-shadow: $green-accent-focus-box-shadow;
}
