// MOBILE STYLES ==============================
.header {
	min-height: 77px;
	position: absolute;
	background: transparent;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.25rem;

	&.sticky {
		position: fixed;
		opacity: 0;
		visibility: hidden;
	}

	&.fade-transition {
		transition: all 1s ease-in-out;
	}

	&.scrolling {
		opacity: 1;
		visibility: visible;
		// transition: all 1s ease-in-out;
		background-color: #000;
	}

	&__inner {
		z-index: 4;
		position: relative;
	}

	a.logo {
		width: 76px;
		margin-left: 3rem;

		img {
			width: 75px;
		}
	}

	&__menu {
		display: inline-block;
		position: absolute;
		padding: 1em;
		top: 15px;
		right: 50px;
		cursor: pointer;

		&.open {
			span:first-child {
				transform: rotate(45deg);
			}

			span:nth-child(2) {
				opacity: 0;
			}

			span:last-child {
				transform: rotate(-45deg);
			}
		}

		span {
			display: block;
			width: 26px;
			height: 2px;
			background-color: white;
			transition: all 300ms ease-in-out;
			transform-origin: 0px 1px;
		}

		span:not(:last-child) {
			margin-bottom: 7px;
		}
	}

	.nav {
		z-index: 3;
		background-color: #000;
		width: 100%;
		position: absolute;
		transform: translate(0, -434px);
		transition: transform 0.3s ease;
		padding: 120px 40px 62px;
		top: 0;

		&.open {
			transform: translate(0, 0);
		}

		ul {
			margin-top: 0;
			border-top: 1px solid rgba(255, 255, 255, 0.07);

			li {
				border-bottom: 1px dotted rgba(255, 255, 255, 0.07);

				&.active a {
					color: $green-accent;
				}
			}

			a {
				display: block;
				padding: 1.3rem 0;
				line-height: 1.3rem;
			}
		}
	}
}

// DESKTOP STYLES ============================
@media (min-width: 800px) {
	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__inner {
			width: 40%;
		}

		&__menu {
			display: none;
		}
		.nav {
			// margin-right: 3rem;
			transform: translate(0, 0px);
			padding: 0;
			background-color: transparent;
			width: 60%;
			position: static;
			margin-right: 2rem;

			ul {
				// display: flex;
				margin-top: 1rem;
				border-top: 0;
				text-align: right;

				li {
					// flex-grow: 1;
					display: inline-block;
					border-bottom: 0;
					padding: 0 1rem;

					a {
						padding: 0;
					}
				}
			}
		}
	}
}

// @media (min-width: 1000px) {
// 	.header {
// 		.nav {
// 			width: 30%;
// 		}
// 	}
// }
