$green-accent: #3bb99b;
$green-accent-hover-bg: #35a88d;
$green-accent-hover-border: #2d947c;
$green-accent-focus-box-shadow: 0 0 0 0.25rem rgb(60 153 110 / 50%);
$heading-color: #0d0a0b;
$text-color: #5f5f5f;
$black-background: #0a0809;

$heading-font: 'Montserrat', sans-serif;
$text-font: 'Roboto', sans-serif;
