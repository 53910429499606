.about-section {
	padding: 7rem 0;

	h2 {
		color: $green-accent;
		font-size: 1.4rem;
		font-weight: 700;
		letter-spacing: 0.15rem;
	}

	h3 {
		font-size: 3.2rem;
		font-weight: 600;
	}

	h4 {
		font-weight: 700;
		font-size: 1.5rem;
	}

	p {
		color: $text-color;
		line-height: 1.875;
	}

	&__header {
		text-align: center;
		margin-bottom: 3rem;
	}

	hr {
		width: 240px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

	&__hello,
	&__skills {
		margin: 4.5rem 0 3rem;
	}

	&__skills {
		.skills-logo-container {
			display: flex;
			align-items: center;
			justify-content: space-around;
			flex-wrap: wrap;
			img {
				width: 75px;
				margin-bottom: 10px;
			}
		}
	}
}
